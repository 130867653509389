// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'cz',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'afbad41c235dd1984fcb3ebcd774ccc5a278fb20',
    releaseTagRef: '4f5e7d7',
    releaseTimestamp: '1728368133',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: 'live_WUWGG77E4BBZLHAZQGUC6XBGFYVQT355',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '6ab5997394a7e70318c5155c74848932',
      branch: '',
      braze: 'e322f07a-684c-4a10-9315-907938b398f6',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyDOtGSKPthz3am_QTmuavFu42QiIcVORm0',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '7cRU4S5h2r8APzvquAsxatRxGWsy7wlP',
      launchDarkly: '6446873ccdfe7612ce507130',
      launchDarklyMobileKey: 'mob-041eab65-0996-46a0-a202-8d753be832f6',
      mParticle: 'eu1-84d972696879dd489a91f188672885a1',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: 'merchant.cz.popeyes',
      migrationMerchantId: '',
      paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-plk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-plk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '30hr4b7kbgbtvbv77ian7h0m9n',
      userPoolId: 'eu-central-1_z6T3UVf4Z',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'adyen',
      marketPaymentsGatewayMerchantId: 'RexConcepts',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-P7DHHCT',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'dev',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.fra-01.braze.eu/api/v3',
      firstData: 'https://api-int.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };